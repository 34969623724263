<template>
  <div class="main">
    <div class="main__text">
      {{ $t('common.text.secure_payment_methods') }}
    </div>
    <div class="main__img">
      <img
        data-src="@images/common/footer/visa.png"
        alt="visa"
        class="lazyload w-[53px] lg:w-[80px] h-[32px] lg:h-[48px]"
        width="80"
        height="48"
      >
      <img
        data-src="@images/common/footer/master-card.png"
        alt="visa"
        class="lazyload mx-6 w-[53px] lg:w-[80px] h-[32px] lg:h-[48px]"
        width="80"
        height="48"
      >
      <img
        data-src="@images/common/footer/paypal.png"
        alt="visa"
        class="lazyload w-[53px] lg:w-[80px] h-[32px] lg:h-[48px]"
        width="80"
        height="48"
      >
    </div>
  </div>
</template>

<script>

export default {
  name: 'MethodPayment',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

  <style lang="scss" scoped>
  .main {
      @apply flex flex-col lg:flex-row justify-center items-center h-[86px] lg:h-24 bg-primary-700;
      &__text {
          @apply text-white mr-6 text__headline-5-xs--semibold lg:text__headline-4-md--semibold mb-3 lg:mb-0;
      }
      &__img {
          @apply flex;
      }
  }
  </style>
